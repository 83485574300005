import React, { useState, useEffect } from "react";
import axios from "axios";

//wrap use in an object
const Email = ({email}) => {
  // const [profiles, setProfiles] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/profiles")
  //     .then((response) => {
  //       setProfiles(response.data);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);
  // // console.log(profiles);

  // const [users, setUsers] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/users")
  //     .then((response) => {
  //       setUsers(response.data);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  // const findProfilesByUserId = (userId, profiles) => {
  //   return profiles.filter((profile) => profile.user === userId);
  // };

  // let userId = "63d9bfdd6a8a377fe41db1c0";
  // console.log(userId)
  // console.log(findProfilesByUserId(findProfilesByUserId(userId, profiles)));
  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        className="btn btn-primary"
        style={{ backgroundColor: "#65d6be" }}
        href={`mailto:${email}`}
        type="button"
      >
        <i className="bi bi-envelope-heart-fill">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-envelope-heart-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555l-4.2 2.568a2.785 2.785 0 0 0-.051-.105c-.666-1.3-2.363-1.917-3.699-1.25-1.336-.667-3.033-.05-3.699 1.25l-.05.105L.05 3.555ZM11.584 8.91a4.694 4.694 0 0 1-.073.139L16 11.8V4.697l-4.003 2.447c.027.562-.107 1.163-.413 1.767Zm-4.135 3.05c-1.048-.693-1.84-1.39-2.398-2.082L.19 12.856A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144L10.95 9.878c-.559.692-1.35 1.389-2.398 2.081L8 12.324l-.551-.365ZM4.416 8.91c-.306-.603-.44-1.204-.413-1.766L0 4.697v7.104l4.49-2.752a4.742 4.742 0 0 1-.074-.138Z" />
            <path d="M8 5.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
          </svg>
        </i>
      </a>
    </div>
  );
};

export default Email;
